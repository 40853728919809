import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import '../styles/Navbar.scss';

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [navItems, setNavItems] = useState([
        { isActive: false, label: "home", route: '/' },
        { isActive: false, label: "about us", route: '/about' },
        { isActive: false, label: "services", route: '/rental' },
        { isActive: false, label: "careers", route: '/careers' },
        { isActive: false, label: "contact", route: '/contact' }
    ]);
    const isMobile = useMediaQuery({ maxWidth: 1024})

    const goToRoute = (route) => {
        setShowMobileNav(false);
        navigate(route);
    }

    useEffect(() => {
        const activeItemIndex = navItems.findIndex(item => item.isActive === true);
        const currentRouteIndex = navItems.findIndex(item => item.route === location.pathname);

        if (activeItemIndex != -1) {
            navItems[activeItemIndex].isActive = false;
        }
        if (currentRouteIndex != -1) {
            navItems[currentRouteIndex].isActive = true;
        }

        setNavItems([...navItems]);
    }, [location]);

    return (
        <div className="navbar">
            <div className='nav-section left-nav' onClick={() => goToRoute('/')}>
                <div className='item logo'>
                    <img alt='vanguard-logo' src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/logos%2Flogo-45x24.png?alt=media&token=6f206117-f9c4-46e6-bf16-4f8410df51d1" />
                </div>

                {
                    !isMobile ? <div className='item word-logo'>
                        <img alt='vanguard-word-logo' src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/logos%2Flogo-word-238x12.png?alt=media&token=f026bfbc-d4f2-40f5-83ad-b86474a08785" />
                    </div> : null
                }
            </div>
            <div className='nav-section right-nav'>
                {
                    isMobile ?
                        <div className='menu-icon' onClick={() => { setShowMobileNav(!showMobileNav) }}>{ showMobileNav ? <FiX size={35} /> : <FiMenu size={35} /> }</div> :
                        navItems?.map(item => (
                            <div key={item.label} className="item">
                                <div onClick={() => goToRoute(item.route)} className={`content ${item.isActive ? 'active' : null}`}>{item.label.toUpperCase()}</div>
                            </div>
                        ))
                }
            </div>
            {
                isMobile ?
                    <div className={`mobile-nav-items ${showMobileNav ? 'active' : ''}`}>
                        {
                            navItems?.map(item => (
                                <div key={item.label} className="item">
                                    <div onClick={() => goToRoute(item.route)} className={`content ${item.isActive ? 'active' : null}`}>{item.label.toUpperCase()}</div>
                                </div>
                            ))
                        }
                    </div> :
                    null
            }
        </div>
    )
}

export default Navbar;