import '../../styles/Careers.scss';
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from 'react-input-mask';
import LongText from '../LongText';
import { addDoc, collection, doc, getDoc, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const Careers = () => {
    const navigate = useNavigate();
    const [recipients, setRecipients] = useState([]);
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const fields = [
        {
            label: "Full Name",
            type: "text",
            id: "fullName",
            required: true
        },
        {
            label: "Email",
            type: "text",
            id: "email",
            required: true
        },
        {
            label: "Phone",
            type: "phone",
            id: "phone",
            required: true
        },
        {
            label: "Education",
            type: "select",
            id: "education",
            options: [
                {
                    name: "High School Diploma",
                    value: "highSchool"
                },
                {
                    name: "Associate's Degree",
                    value: "associate"
                },
                {
                    name: "Bachelor's Degree",
                    value: "bachelor"
                },
                {
                    name: "Master's Degree",
                    value: "master"
                },
            ],
            required: true
        },
        {
            label: "Position",
            type: "select",
            id: "position",
            options: [
                {
                    name: "Editor",
                    value: "editor"
                },
                {
                    name: "Gameplay Observer",
                    value: "observer"
                },
                {
                    name: "General Event Staff",
                    value: "generalStaff"
                },
                {
                    name: "Graphics Artist",
                    value: "graphics"
                },
                {
                    name: "Photographer",
                    value: "photographer"
                },
                {
                    name: "Replay Observer",
                    value: "replay"
                },
                {
                    name: "Technical Director",
                    value: "technicalDirector"
                },
                {
                    name: "Tournament Admin",
                    value: "tournamentAdmin"
                },
                {
                    name: "Tournament Organizer",
                    value: "tournamentOrganizer"
                },

                {
                    name: "Videographer",
                    value: "videographer"
                },
            ],
            required: true
        },
        {
            label: "Resume/CV",
            type: "text",
            id: "resume",
            required: true,
            placeholder: "Google Drive folder works great!"
        },
        {
            label: "What is your availability?",
            type: "longText",
            id: "availability",
            placeholder: "Days and Hours",
            required: true
        },
        {
            label: "Do you have any relevant experience with video games? (e.g. tournament organization, stream production, computer science skills, pro gamer, etc). Please explain or link relevant portfolio, youtube, or twitch channels.",
            type: "longText",
            id: "experience",
            required: false
        },
        {
            label: "Please explain why you want to work at Vanguard and anything else you'd like us to know about you",
            type: "longText",
            id: "whyVanguard",
            required: true
        }
    ];
    const [showOverlay, setShowOverlay] = useState(false);

    const onSubmit = async (data) => {

        try {
            await addDoc(collection(db, "mail"), {
                to: recipients,
                template: {
                    name: 'careersApplication',
                    data: {
                        availability: data.availability,
                        education: data.education,
                        email: data.email,
                        experience: data.experience,
                        fullName: data.fullName,
                        phone: data.phone,
                        position: data.position,
                        whyVanguard: data.whyVanguard,
                        resume: data.resume
                    }
                }
            });

            setShowOverlay(true);
        }
        catch(ex) {
            console.error(ex);
        }
    }

    const goToHome = () => {
        navigate('/');
    }

    const renderField = (formField) => {
        switch (formField.type) {
            case "text":
                return (
                    <input id={formField.id} type="text" placeholder={formField.placeholder} className='input' {...register(formField.id, { required: formField.required })} />
                )
            case "phone":
                return (
                    <Controller
                        name={formField.id}
                        control={control}
                        rule={{ required: formField.required }}
                        render={({ field }) => (
                            <ReactInputMask value={field.value ?? ""} className="input" onChange={(date) => field.onChange(date)} mask="(999) 999-9999" />
                        )} />
                )
            case "select":
                return (
                    <select id={formField.id} defaultValue="default" className="input" {...register(formField.id, { required: formField.required })}>
                        <option value="default" disabled></option>
                        {
                            formField.options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)
                        }
                    </select>
                )
            case "longText":
                return (
                    <Controller
                        name={formField.id}
                        control={control}
                        rule={{ required: formField.required }}
                        render={({ field }) => (
                            <LongText onChange={field.onChange} content={field.value} showMenu={false} />
                        )} />
                )
        }
    }

    const getRecipients = async () => {
        const q = query(doc(db, 'admin', 'careers'));
        const docSnap = await getDoc(q);

        if(docSnap.exists() && docSnap.data().recipients) {
            setRecipients(docSnap.data().recipients);
        }
    }

    useEffect(() => {
        getRecipients();
    }, [])

    return (
        <div className="careers-page">
            <div className='content'>
                <div className="header">
                    <h1>Vanguard strives to provide unique and constantly growing experiences</h1>
                </div>
                <div className='tagline'>
                    <h4>If you think you'll be a good fit, please fill out the below form and attach a CV/Resume with relevant experience. If you have a portfolio, please link that to us as well!</h4>
                </div>
                <div className='form-container'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='panel'>
                            {
                                fields?.map(field => (
                                    <div key={field.id} className='row'>
                                        <div className='field'>
                                            <div className='label-group'>
                                                <label htmlFor={field.id}>{field.label}</label>
                                                {
                                                    field.required ? <div className="error">{errors[field.id]?.type === 'required' && "(required)"}</div> : null
                                                }
                                            </div>
                                            <div className='control'>
                                                {renderField(field)}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            <div className='row'>
                                <div className='submit-button'>
                                    <button type="submit" className='button primary'>Apply</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className={`overlay ${showOverlay ? 'visible' : ''}`}>
                <div className='panel'>
                    <div className='header'>Application Sent</div>
                    <div className='body'>
                        <p>Thanks for reaching out! If you're a good fit for what we need, we will contact you ASAP!</p>
                        <button className='primary button' onClick={goToHome}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Careers;