import { useState } from "react";
import { useEffect } from "react";
import '../styles/QuantityDropdown.scss';
import { toast } from 'react-toastify';

const QuantityDropdown = ({ item, quantityChange }) => {
    const [isTenOrMore, setIsTenOrMore] = useState(false);
    const options = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10+", "Delete"];

    const onInputBlur = (itemId) => {
        if (item.quantity === '0') {
            quantityChange('Delete', itemId);
        }
    }

    useEffect(() => {
        const quantity = parseInt(item.quantity);

        if (quantity > item.availableStock) {
            toast.error("Can't reserve more than amount available");
            quantityChange(item.availableStock, item.product.id);
        }

        if (quantity >= 10 || isTenOrMore) {
            setIsTenOrMore(true)
        }
        else {
            setIsTenOrMore(false);
        }
    }, [item.quantity]);

    return (
        <div className="quantity-dropdown">
            {
                !isTenOrMore ?
                    <select className="quantity-change input" value={item.quantity} onChange={(e) => quantityChange(e.target.value, item.product.id)}>
                        {
                            options.map(option => <option key={option} value={option}>{option}</option>)
                        }
                    </select> :
                    <input className="quantity-change input" type="number" min="0" value={item.quantity === "10+" ? "10" : item.quantity} onBlur={(e) => onInputBlur(item.product.id)} onChange={(e) => quantityChange(e.target.value, item.product.id)} />
            }
        </div>
    )
}

export default QuantityDropdown;