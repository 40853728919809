import React from 'react'
import StarterKit from '@tiptap/starter-kit'
import Youtube from '@tiptap/extension-youtube'
import { useEditor, EditorContent } from '@tiptap/react'

import '../styles/LongText.scss';

const MenuBar = ({ editor }) => {
    if (!editor) {
        return null
    }

    const addYoutubeVideo = () => {
        const url = prompt('Enter YouTube URL')
    
        if (url) {
          editor.commands.setYoutubeVideo({
            src: url,
          })
        }
      }

    return (
        <div class="menu-container">
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                bold
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                italic
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleStrike().run()}
                className={editor.isActive('strike') ? 'is-active' : ''}
            >
                strike
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
                Heading 1
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            >
                Heading 2
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            >
                Heading 3
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
            >
                Heading 4
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
            >
                Heading 5
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
            >
                Heading 6
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                bullet list
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
                ordered list
            </button>
            <button
                type="button" onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                blockquote
            </button>
            <button type="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                horizontal rule
            </button>
            <button type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>
                hard break
            </button>
            <button type="button" onClick={() => editor.chain().focus().undo().run()}>
                undo
            </button>
            <button type="button" onClick={() => editor.chain().focus().redo().run()}>
                redo
            </button>
            <button id="add" type="button" onClick={addYoutubeVideo}>Add YouTube video</button>
        </div>
    )
}

export default ({ content, onChange, showMenu = true }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Youtube.configure({
                controls: true,
                modestBranding: 'true',
            })
        ],
        onUpdate({ editor }) {
            onChange(editor.getHTML());
        },
        content: content
    })

    return (
        <div className='longtext'>
            {
                showMenu ?
                    <MenuBar editor={editor} /> :
                    null
            }
            <EditorContent editor={editor} />
        </div>
    )
}