import HeroImage from '../HeroImageSection';
import SolutionsSection from '../SolutionsSection';
import ProductionSection from '../ProductionSection';
import RentalSection from '../RentalSection';
import StaffingSection from '../StaffingSection';
import CtaSection from '../CtaSection';

const Home = () => {
    return (
        <div className="home-page">
            <HeroImage />
            <SolutionsSection />
            <ProductionSection />
            <RentalSection />
            <StaffingSection />
            <CtaSection />
        </div>
    )
}

export default Home;