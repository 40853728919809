import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import '../styles/SolutionsSection.scss';
import { FiArrowLeft } from 'react-icons/fi';

const SolutionsSection = () => {
    const [solutions, setSolutions] = useState([
        {
            name: "Events",
            children: ["Tournaments", "Festivals", "Leagues"],
            isActive: false
        },
        {
            name: "Production",
            children: ["Live Broadcasting", "Recorded Video", "Design & Motion Graphics"],
            isActive: false
        },
        {
            name: "Analysis",
            children: ["Revenue and Monetization", "Marketing and Media"],
            isActive: false
        },
        {
            name: "CONSULTING",
            children: ["Staffing Solutions", "Facility Development", "Projectd Management"],
            isActive: false
        }
    ]);
    const isMobile = useMediaQuery({ maxWidth: 1024})

    const setActiveSolution = (selectedSolution) => {
        const solutionsArray = [...solutions];
        solutionsArray?.map(solution => {
            if (solution.name === selectedSolution.name) {
                solution.isActive = !solution.isActive
            }
        });

        setSolutions(solutionsArray);
    }

    return (
        <div className='solutions'>
            {
                !isMobile ? <div className='side-image'>
                <img src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/home-page%2F310dae4767cb2258c288d693cc93d52e.jfif?alt=media&token=a10ebf6c-0470-45d1-b388-40239a94bde6" />
            </div> : null
            }
            <div className='content'>
                <h1>START TO FINISH SOLUTIONS<div className='underline'></div></h1>
                {
                    isMobile ?
                        <div className="mobile-items">
                            {
                                solutions?.map(solution => (
                                    <div key={solution.name} className="item">
                                        <div className="header">{ solution.name }</div>
                                        {
                                            solution?.children?.map(child => <div key={child} className="child">{child}</div>)
                                        }
                                    </div>
                                ))
                            }
                        </div> :
                        <div className='items'>
                            {
                                solutions?.map(solution => (
                                    <div key={solution.name} className='item' onClick={() => setActiveSolution(solution)}>
                                        <div className={`sliding-door ${solution.isActive ? 'active' : ''}`}><FiArrowLeft /> {solution.name}</div>
                                        <div className='behind-door'>{
                                            solution?.children?.map(child => <div key={child}>{child}</div>)
                                        }</div>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default SolutionsSection;