import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import '../styles/UserRow.scss';

const UserRow = ({user}) => {
    const changeUserAccess = async (event) => {
        const isAdmin = event.target.checked;
        const userDocRef = doc(db, 'users', user.id);
        await updateDoc(userDocRef, {isAdmin: isAdmin})
    }

    const deleteUser = async () => {
        if(window.confirm("Are you sure you want to delete this user?")) {
            const userDocRef = doc(db, 'users', user.id);
            await deleteDoc(userDocRef);
        }
    }

    return (
        <tr className="user-row">
            <td>{user.data.name}</td>
            <td>{user.data.email}</td>
            <td><input type="checkbox" checked={user.data.isAdmin} onChange={(event) => { changeUserAccess(event) }} /></td>
            <td><div className="delete" onClick={deleteUser}>Delete</div></td>
        </tr>
    )
}

export default UserRow;