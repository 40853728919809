import '../../styles/Login.scss';
import { signInWithGoogle } from "../../firebase";
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const navigate = useNavigate();

    const adminLogin = async () => {
        await signInWithGoogle();
        navigate('/admin')
    }

    return (
        <div className="login-page">
            <div className="panel">
                <div className='header'>
                    <h1>Admin Portal</h1>
                </div>
                <div className='google-login'>
                    <button className='button primary' onClick={adminLogin}>Vanguard Login</button>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;