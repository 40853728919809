import { Controller, useForm } from "react-hook-form";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import '../../styles/RentalContactInfo.scss';
import { states } from '../../assets/States.js';
import ReactInputMask from "react-input-mask";
import { addDoc, collection, doc, getDoc, query } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

const RentalContactInfo = () => {
    const navigate = useNavigate();
    const [cartContents, setCartContents] = useLocalStorage("cartContents", []);
    const [eventRange, setEventRange] = useLocalStorage("eventRange", {})
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [recipients, setRecipients] = useState([]);

    const onSubmit = async (data) => {
        try {
            await addDoc(collection(db, "equipmentRequests"), {
                contactInfo: data,
                equipment: cartContents,
                eventRange: eventRange
            });

            addDoc(collection(db, "mail"), {
                to: recipients,
                template: {
                    name: 'equipmentRequests',
                    data: {
                        eventRange: `${moment(eventRange?.selection?.startDate).format("MM/DD/YYYY")} to ${moment(eventRange?.selection?.endDate).format("MM/DD/YYYY")}`
                    }
                }
            })

            setCartContents([]);
            setEventRange({});

            navigate('/confirmation');
        }
        catch (ex) {
            console.error(ex);
            alert("Something went wrong! Please try again");
        }
    }

    const getRecipients = async () => {
        const q = query(doc(db, 'admin', 'equipmentRequests'));
        const docSnap = await getDoc(q);

        if(docSnap.exists() && docSnap.data().recipients) {
            setRecipients(docSnap.data().recipients);
        }
    }

    useEffect(() => {
        getRecipients();
    }, [])

    return (
        <div className="rental-contact-info">
            <div className="panel">
                <div className="panel-content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <h4>Personal Details</h4>
                        </div>
                        <div className="row">
                            <div className="field">
                                <div className="label-group">
                                    <label htmlFor="first-name">First Name</label>
                                    <div className="error">{errors.firstName?.type === 'required' && "(required)"}</div>
                                </div>
                                <input className="input" id="first-name" type="text" {...register("firstName", { required: true })} />
                            </div>

                        </div>
                        <div className="row">
                            <div className="field">
                                <div className="label-group">
                                    <label htmlFor="last-name">Last Name</label>
                                    <div className="error">{errors.lastName?.type === 'required' && "Required"}</div>
                                </div>
                                <input id="last-name" className="input" type="text" {...register("lastName", { required: true })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                                <div className="label-group">
                                    <label htmlFor="phone">Phone Number</label>
                                    <div className="error">{errors.phone?.type === 'required' && "Required"}</div>
                                </div>
                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <ReactInputMask value={field.value ?? ""} className="input" onChange={(date) => field.onChange(date)} mask="(999) 999-9999" />
                                    )} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                                <div className="label-group">
                                    <label htmlFor="email">Email</label>
                                    <div className="error">{errors.email?.type === 'required' && "Required"}</div>
                                </div>
                                <input id="email" className="input" type="text" {...register("email", { required: true })} />
                            </div>
                        </div>
                        <div className="row">
                            <h4>Event Details</h4>
                        </div>
                        <div className="row">
                            <div className="field">
                                <div className="label-group">
                                    <label htmlFor="address1">Address Line 1</label>
                                    <div className="error">{errors.address1?.type === 'required' && "Required"}</div>
                                </div>
                                <input id="address1" className="input" type="text" {...register("address1", { required: true })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                                <label htmlFor="address2">Address Line 2</label>
                                <input id="address2" className="input" type="text" {...register("address2")} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                                <div className="label-group">
                                    <label htmlFor="city">City</label>
                                    <div className="error">{errors.city?.type === 'required' && "Required"}</div>
                                </div>
                                <input id="city" className="input" type="text" {...register("city", { required: true })} />
                            </div>

                        </div>
                        <div className="row">
                            <div className="field state">
                                <div className="label-group">
                                    <label htmlFor="state">State</label>
                                    <div className="error">{errors.state?.type === 'required' && "Required"}</div>
                                </div>
                                <select id="state" className="input" {...register("state", { required: true })}>
                                    {
                                        states.map(state => <option key={state} value={state}>{state}</option>)
                                    }
                                </select>

                            </div>
                            <div className="field zip">
                                <div className="label-group">
                                    <label htmlFor="zip">Zip</label>
                                    <div className="error">{errors.zip?.type === 'required' && "Required"}</div>
                                </div>
                                <Controller
                                    name="zip"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <ReactInputMask className="input" value={field.value ?? ""} onChange={(date) => field.onChange(date)} mask="99999" />
                                    )} />
                            </div>
                        </div>

                        <div className="create-request">
                            <button type="submit" className="primary button">Create Equipment Request</button>
                        </div>
                    </form>
                </div>
            </div>


        </div>
    )
}

export default RentalContactInfo;