import ProductCell from "../ProductCell";
import '../../styles/EquipmentRental.scss';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import RentalDateNavbar from "../RentalDateNavbar";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import EquipmentRentalTutorial from "../EquipmentRentalTutorial";

const EquipmentRental = ({setCartContents, setShowCart}) => {
    const [products, setProducts] = useState();
    const [dateRange, setDateRange] = useState(() => {
        return JSON.parse(localStorage.getItem("eventRange"));
    });
    const [searchedProducts, setSearchedProducts] = useState();
    const [filteredProducts, setFilteredProducts] = useState();
    const [activeFilter, setActiveFilter] = useLocalStorage("activeFilter", "");

    const getProducts = () => {
        const q = query(collection(db, 'products'), orderBy("name"));
        onSnapshot(q, (querySnapshot) => {
            let snap = querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            }));
            
            if(products) {
                snap = [...products];
            }
            setProducts(snap);
            setSearchedProducts(snap);
            setFilteredProducts(snap);
        });
    }

    const searchTermChange = (term) => {
        if (term === "") {
            setSearchedProducts(products);
        }
        else {
            const tempSearchedProducts = products.filter(product => product.data.name.toLowerCase().includes(term));
            setSearchedProducts(tempSearchedProducts);
        }
    }

    const filterChange = (filter) => {
        setActiveFilter(filter);
        if (filter === "") {
            setFilteredProducts(searchedProducts);
        }
        else {
            const tempFilteredProducts = searchedProducts?.filter(product => product.data.keywords?.includes(filter));
            setFilteredProducts(tempFilteredProducts);
        }
    }

    const dateChange = (dateRange) => {
        setDateRange(dateRange);
    }

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        filterChange(activeFilter);
    }, [searchedProducts]);


    return (
        <div className="equipment-container">
            <div className="filters">
                <RentalDateNavbar notifySearchTermChange={searchTermChange} notifyFilterChange={filterChange} notifyDateChange={dateChange} />
            </div>
            <div className="content">
                <div className="intro">
                    <EquipmentRentalTutorial />
                </div>
                <div className="equipment-rental">
                    {
                        filteredProducts?.length > 0 ?
                            filteredProducts?.map(product =>
                                <ProductCell key={product.id} productInfo={product} dateRange={dateRange} setCartContents={setCartContents} setShowCart={setShowCart} />
                            ) :
                            <div className="no-results-found">No search results found</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EquipmentRental;