import { doc, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";

export const getFilters = async (filterName, setFilter) => {
    const q = query(doc(db, 'admin', filterName));
    onSnapshot(q, (querySnapshot) => {
        const data = querySnapshot.data()?.filters || [];
        const sortedData = data.sort((a, b) => {
            if (a.keyword < b.keyword) return -1;
            if (a.keyword > b.keyword) return 1;
            return 0;
        });

        setFilter(sortedData);
    })
}