
import UserManagement from "../UserManagement";
import '../../styles/Admin.scss';
import AdminProductList from "../AdminProductList";
import { logout } from "../../firebase";
import { useNavigate } from "react-router-dom";
import EquipmentRequests from "../EquipmentRequests";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import AdminCareerSettings from "../AdminCareerSettings";
import AdminContactSettings from "../AdminContactSettings";
import EditFilters from "../EditFilters";

const AdminPage = () => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useLocalStorage('adminActiveNavItem', 'requests');
    const menuItems = [
        "Requests",
        "Users",
        "Products",
        "Careers",
        "Contact"
    ]

    const renderContent = () => {
        switch (activeItem) {
            case "users":
                return (
                    <div className="user-management-container">
                        <UserManagement />
                    </div>
                )
            case "requests":
                return (
                    <div className="equipment-requests-container">
                        <EquipmentRequests />
                    </div>
                )
            case "products":
                return (
                    <div className="products">
                        <AdminProductList />
                        <div className="filter-container">
                            <EditFilters filterType="productFilters" filterName="Product Filters" />
                            <EditFilters filterType="fullServiceFilters" filterName="Full Service Filters" />
                        </div>
                    </div>
                )
            case "careers":
                return (
                    <div className="careers">
                        <AdminCareerSettings />
                    </div>
                )
            case "contact":
                return (
                    <div className="contact">
                        <AdminContactSettings />
                    </div>
                )
        }
    }

    const logoutUser = () => {
        logout(navigate);
    }

    return (
        <div className="admin-page">
            <div className="menu">
                <div>
                    {
                        menuItems?.map(item => (
                            <div key={item} onClick={() => setActiveItem(item.toLowerCase())} className={`item ${activeItem === item.toLowerCase() ? 'active' : ''}`}>
                                {item}
                            </div>
                        ))
                    }
                </div>
                <div className="logout">
                    <button className="button" onClick={logoutUser}>Log out</button>
                </div>
            </div>
            <div className="content">
                {renderContent()}
            </div>
        </div>
    )
}

export default AdminPage;