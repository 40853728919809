import { useState } from "react";
import '../styles/ProductionSection.scss';

const ProductionSection = () => {
    const [production] = useState([
        "STAFFING", "TRAVEL", "BRANDING", "STAGING", "OPERATIONS", "BROADCAST", "DESIGN", "VIDEOGRAPHY"
    ]);

    return (
        <div className='production'>
            <div className='shade'>
                <div className='content-gradient'>
                    <div className='content'>
                        <h1>PRODUCTION</h1>
                        <div className='production-items'>
                            {
                                production?.map(item => <div key={item} className='item'>{item}</div>)
                            }
                        </div>

                        <div className='tagline'>No matter the need, we have you covered</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductionSection;