import { FiArrowRight } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import '../styles/RentalSection.scss';
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useEffect } from 'react';
import { useState } from 'react';
import { getFilters } from '../services/getFilters';

const RentalSection = () => {
    const navigate = useNavigate();
    const [activeFilter, setActiveFilter] = useLocalStorage("activeFilter", "");
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    const [equipmentFilters, setEquipmentFilters] = useState([]);
    const [fullServiceFilters, setFullServiceFilters] = useState([]);

    const goToRental = (keyword) => {
        setActiveFilter(keyword);
        navigate('/rental');
    }

    useEffect(() => {
        getFilters('productFilters', setEquipmentFilters);
        getFilters('fullServiceFilters', setFullServiceFilters);
    }, [])

    return (
        <div className='rental'>
            <div className='shade'>
                <div className='header'>
                    <h1>LET US HELP YOU BRING YOUR VISION TO LIFE</h1>
                </div>

                {
                    isMobile ?
                        null :
                        <div className='items'>
                            <div className='section'>
                                <h1>Equipment Rental</h1>
                                <ul className='list'>
                                    {
                                        equipmentFilters.map(filter => (
                                            <li key={filter.keyword} onClick={() => goToRental(filter.keyword)}>{filter.name}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className='section'>
                                <h1>Full Service & Staffing</h1>
                                <ul className='list'>
                                    {
                                        fullServiceFilters.map(filter => (
                                            <li key={filter.keyword} onClick={() => goToRental(filter.keyword)}>{filter.name}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                }

                <div className='footer'>
                    <h4 onClick={() => goToRental("")}>For those that know the way, but may not have the right tools.</h4>
                    <span className='arrow'><FiArrowRight /></span>
                </div>

                {
                    isMobile ?
                        <div className="mobile-footer">We got the goods, and we're here to share</div> :
                        null
                }
            </div>
        </div>
    )
}

export default RentalSection;