import '../styles/HeroImageSection.scss';
import Typewriter from 'typewriter-effect';

const HeroImage = () => {
    return (
        <div className="hero-image">
            <div className='shade'>
                <div className='typewriter'>
                    <Typewriter onInit={(typewriter) => {
                        typewriter
                            .pauseFor(1000)
                            .typeString('WE WORK')
                            .pauseFor(300)
                            .typeString('<br /> SO YOU CAN PLAY')
                            .pauseFor(2000)
                            .deleteChars(4)
                            .typeString('CREATE')
                            .pauseFor(2000)
                            .deleteChars(6)
                            .typeString('INNOVATE')
                            .pauseFor(2000)
                            .deleteChars(8)
                            .typeString('WIN')
                            .start()
                    }}
                        options={{ deleteSpeed: 40 }} />
                </div>
            </div>
        </div>
    )
}

export default HeroImage;