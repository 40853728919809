import { useState } from "react";
import '../styles/RentalDateNavbar.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import DateRangePicker from "./DateRangePicker";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useMediaQuery } from 'react-responsive';
import { FiChevronUp } from 'react-icons/fi';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useEffect } from "react";
import { doc, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";

const RentalDateNavbar = ({ notifyDateChange, notifySearchTermChange, notifyFilterChange, hideSorting }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    const [equipmentFilters, setEquipmentFilters] = useState([]);
    const [fullServiceFilters, setFullServiceFilters] = useState([]);
    const [mobileNavExpanded, setMobileNavExpanded] = useState(false);
    const [showDateSelector, setShowDateSelector] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeFilter, setActiveFilter] = useLocalStorage("activeFilter", "");
    const [dateRangeDisplay, setDateRangeDisplay] = useState(() => {
        const storedValue = JSON.parse(localStorage.getItem("eventRange"));

        if (storedValue) {
            const startDate = moment(storedValue?.selection?.startDate);
            const endDate = moment(storedValue?.selection?.endDate);
            const dayDiff = endDate.diff(startDate, 'days') + 1;

            return (
                <div className="current-date">
                    <div className="date">{`${moment(startDate).format("MM/DD/YYYY")}`}</div>
                    <div>{` for ${dayDiff} day${dayDiff > 1 ? 's' : ''}`}</div>
                </div>
            )
        }
        else {
            return ''
        }
    });


    const dateSelectCallback = (dateRange) => {
        if (notifyDateChange) {
            notifyDateChange(dateRange);
        }
        const startDate = moment(dateRange.startDate);
        const endDate = moment(dateRange.endDate);
        const dayDiff = endDate.diff(startDate, 'days') + 1;

        setDateRangeDisplay(
            <div className="current-date">
                <div className="date">{`${moment(startDate).format("MM/DD/YYYY")}`}</div>
                <div>{` for ${dayDiff} day${dayDiff > 1 ? 's' : ''}`}</div>
            </div>
        )
    }

    const clearFilter = () => {
        setActiveFilter("");
        notifyFilterChange("");
        setMobileNavExpanded(false);
    }

    const closeDatePicker = () => {
        setShowDateSelector(false);
    }

    const ref = useDetectClickOutside({ onTriggered: closeDatePicker });

    const renderFilters = (renderArray) => {
        return renderArray?.map(filter => (
            <div
                id={`${filter.keyword}-filter`}
                key={filter.keyword}
                className={`filter ${`${activeFilter}-filter` === `${filter.keyword}-filter` ? 'active' : ''}`}
                onClick={() => {
                    setActiveFilter(filter.keyword);
                    notifyFilterChange(filter.keyword);
                    setMobileNavExpanded(false);
                }}>
                {filter.name}
            </div>
        ))
    }

    const getFilters = async (filterName, setFilter) => {
        const q = query(doc(db, 'admin', filterName));
        onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.data()?.filters || [];
            const sortedData = data.sort((a, b) => {
                if(a.keyword < b.keyword) return -1;
                if(a.keyword > b.keyword) return 1;
                return 0;
            });

            setFilter(sortedData);
        })
    }

    useEffect(() => {
        getFilters('productFilters', setEquipmentFilters);
        getFilters('fullServiceFilters', setFullServiceFilters);
    }, [])

    return (
        <div className="rental-date-navbar" ref={ref}>
            {
                isMobile ?
                    <div className="mobile-navbar" onClick={() => setMobileNavExpanded(!mobileNavExpanded)}>
                        <span>{hideSorting ? 'Set Date' : 'Search Tools'}</span>
                        <FiChevronUp className={`mobile-chevron ${mobileNavExpanded ? 'expanded' : ''}`} />
                    </div> :
                    null
            }
            <div className={`content ${mobileNavExpanded ? 'active' : ''}`}>
                <div className="item toggle-button">
                    {
                        dateRangeDisplay
                    }
                    <div>
                        <button className="button" onClick={() => setShowDateSelector(!showDateSelector)}>Choose Date</button>
                    </div>
                </div>
                <div className="item date-selector">
                    {
                        showDateSelector ?
                            <DateRangePicker dateSelectCallback={dateSelectCallback} /> :
                            null
                    }
                </div>
                {
                    !hideSorting ?
                    <div>
                    <hr />
                    <div className="item search-bar">
                        <input className="input" type="text" placeholder="Search" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); notifySearchTermChange(e.target.value) }} />
                    </div>
                    <div className="item filter-section">
                        <div className="header">
                            <div>
                                Equipment
                            </div>
                            {
                                activeFilter != "" ?
                                    <div className="clear-filter" onClick={clearFilter}>clear filter</div> :
                                    null
                            }
                        </div>
                        <div className="filters">
                            {
                                renderFilters(equipmentFilters)
                            }
                        </div>
                    </div>
                    <div className="item filter-section">
                        <div className="header">
                            Full Service
                        </div>
                        <div className="filters">
                            {
                                renderFilters(fullServiceFilters)
                            }
                        </div>
                    </div>
                </div> :
                null
                }
            </div>
        </div>
    )
}

export default RentalDateNavbar;