import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../../styles/Confirmation.scss';

const Confirmation = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    }

    useEffect(() => {
        setTimeout(() => {
            goToHome();
        }, 30000);
    }, []);

    return (
        <div className="confirmation">
            <div className="panel">
                <div>
                    <h1>Your request has been submitted!</h1>
                    <div>We'll send you an email when it's reviewed and approved, or if we have any further questions. Please contact <a href="mailto:ben.ng@outlook.com">ben.ng@outlook.com</a> if you have any questions!</div>
                </div>
                <div>
                    You will be automatically directed back to the home page in 30 seconds. If it doesn't redirect you, or you want to go there sooner, <span className="click" onClick={goToHome}>click here</span>!
                </div>
            </div>
        </div>
    )
}

export default Confirmation;