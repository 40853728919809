import Typewriter from 'typewriter-effect';
import '../styles/StaffingSection.scss';

const StaffingSection = () => {
    return (
        <div className='staffing'>
            <div className='header'>
                <h1>GOT A JOB BUT DON'T HAVE ENOUGH HANDS?</h1>
            </div>
            <div className='side-image'>
                <img src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/home-page%2Ff8cf4693313eeb87b6bab43ad51f81b8.jfif?alt=media&token=d9ee4ab5-0604-4fc2-b5e3-82f1ffb0674a" />
            </div>
            <div className='content'>
                <div className='label'>
                    <h1>EXPERTS FOR ANY ROLE</h1>
                </div>
                <div className='typewriter'>
                    <Typewriter onInit={(typewriter) => {
                        typewriter
                            .typeString('ON-CAMERA TALENT')
                            .pauseFor(500)
                            .deleteAll()
                            .typeString('INTERVIEWERS & HOSTS')
                            .pauseFor(500)
                            .deleteAll()
                            .typeString('TECHNICAL DIRECTORS & OPERATORS')
                            .pauseFor(500)
                            .deleteAll()
                            .typeString('OBSERVERS & GAMEPLAY DIRECTORS')
                            .pauseFor(500)
                            .deleteAll()
                            .typeString('TOURNAMENT OPERATORS')
                            .pauseFor(500)
                            .deleteAll()
                            .typeString('PHOTOGRAPHY & VIDEOGRAPHY')
                            .pauseFor(500)
                            .deleteAll()
                            .typeString('LIGHTING & CAMERA')
                            .pauseFor(500)
                            .deleteAll()
                            .typeString('GENERAL EVENT STAFF')
                            .pauseFor(500)
                            .deleteAll()
                            .start()
                    }}
                        options={{ deleteSpeed: 40, loop: true }} />
                </div>
            </div>
        </div>
    )
}

export default StaffingSection;