import '../styles/ProductCell.scss';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from 'react';
import { useEffect } from 'react';
import { TailSpin } from "react-loading-icons";
import QuickAddQuantity from './QuickAddQuantity';
import { toast } from 'react-toastify';

const ProductCell = ({ productInfo, dateRange, setCartContents, setShowCart }) => {
    const navigate = useNavigate();
    const [availableStock, setAvailableStock] = useState();
    const [quantity, setQuantity] = useState(0);

    const getCurrentAvailableStock = async () => {
        const availableStockFunction = httpsCallable(getFunctions(), 'getProductAvailablityByDate');
        const blankDateRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            userSet: false
        };

        if (dateRange?.selection) {
            dateRange = dateRange.selection
        }

        const stock = await availableStockFunction({
            product: productInfo,
            eventRange: dateRange ? dateRange : blankDateRange
        });
        setAvailableStock(stock.data);
    }

    const quantityChange = (value) => {
        setQuantity(value);
    }

    const addToCart = () => {
        const quantityInt = parseInt(quantity);

        if(quantityInt > availableStock) {
            toast.error("Can't reserve more than amount available");
        }
        else if(quantityInt === 0) {
            toast.error("Please set a quantity");
        }
        else {
            setCartContents(currentCart => {
                let existingItemIndex;
                const existingItem = currentCart.filter((item, index) => {
                    existingItemIndex = index;
                    return item.product.id === productInfo.id
                });

                if(existingItem.length > 0) {
                    currentCart.splice(existingItemIndex, 1);
                    existingItem[0].quantity = parseInt(existingItem[0].quantity ? existingItem[0].quantity : 0) + parseInt(quantityInt);

                    return [...currentCart, existingItem[0]];
                }
                else {
                    return [...currentCart, {
                        product: productInfo,
                        quantity: quantityInt,
                        availableStock: availableStock
                    }]
                }
            });

            setShowCart(true);
            setQuantity("0");
        }
    }

    useEffect(() => {
        getCurrentAvailableStock();
    }, [dateRange])

    return (
        <div className="product-cell">
            <div className='cell-content'>
                <div className='thumbnail' onClick={() => navigate(`/product/${productInfo.data.slug}`)}>
                    <img src={productInfo.data.thumbnailUrl} />
                </div>
                <hr />
                <div className='info'>
                    <div className='title' onClick={() => navigate(`/product/${productInfo.data.slug}`)}>{productInfo.data.name}</div>
                    <div className='inventory'>
                        <div className='price'>
                            ${productInfo.data.price}<span className='label'>/day</span>
                        </div>
                        {
                            availableStock ?
                                <div className='available'>
                                    <div className='value'>
                                        {availableStock}
                                    </div>
                                    <div className='label'>
                                        available for your event
                                    </div>
                                </div> :
                                <div>
                                    {
                                        dateRange ?
                                            <div>
                                                <TailSpin stroke="#121212" height='1em' /> <span>Getting availability...</span>
                                            </div> :
                                            <div>{productInfo.data.stock} total available</div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className='footer'>
                <QuickAddQuantity quantity={quantity} quantityChange={quantityChange} />
                <button className='button primary' onClick={addToCart}>Add</button>
            </div>
        </div>
    )
}

export default ProductCell;