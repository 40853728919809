import LongText from "../LongText";
import '../../styles/AddEditProduct.scss';
import { Controller, useForm } from "react-hook-form";
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useLocation, useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useState } from "react";
import { useEffect } from "react";
import { getFilters } from "../../services/getFilters";

const AddEditProduct = () => {
    const navigate = useNavigate();
    const [equipmentFilters, setEquipmentFilters] = useState([]);
    const [fullServiceFilters, setFullServiceFilters] = useState([]);
    const { register, handleSubmit, control, formState: { errors }, setValue, getValues, reset } = useForm();
    const { state } = useLocation();
    const [doneLoading, setDoneLoading] = useState(false);
    const onSubmit = async (data) => {
        debugger;
        try {
            if(getValues("imageFile") != null) {
                data.thumbnailUrl = await uploadProductImage(data);
                data.imageFile = null;
            }

            if (!state) {
                await addDoc(collection(db, 'products'), {
                    ...data
                });
            }
            else {
                const productDocRef = doc(db, 'products', state.id);
                await updateDoc(productDocRef, { ...data })
            }
            navigate('/admin');
        }
        catch (ex) {
            console.error(ex);
        }
    };

    const uploadProductImage = async (data) => {
        const imageRef = ref(storage, `products/${data.slug}.jpg`);
        if(data.imageFile.length > 0) {
            const result = await uploadBytes(imageRef, data.imageFile[0]);
            const imageUrl = await getDownloadURL(result.ref);

            return imageUrl;
        }
    }

    const setProductData = () => {
        if (state) {
            setDoneLoading(false);
            for (var key in state.data) {
                console.log(`${key}: ${state.data[key]}`);
                setValue(key, state.data[key]);
            }
            setDoneLoading(true);
        }
        else {
            setDoneLoading(true);
        }
    }

    const nameChange = (e) => {
        const slugName = e.target.value.toLowerCase().replaceAll(' ', '-');
        setValue('slug', slugName);
    }

    useEffect(() => {
        getFilters('productFilters', setEquipmentFilters);
        getFilters('fullServiceFilters', setFullServiceFilters);
    }, [])

    useEffect(() => {
        setProductData();
    }, [equipmentFilters, fullServiceFilters])

    return (
        <div className="edit-product">
            {
                doneLoading ?
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                    <div className="label-group">
                        <label htmlFor="name">Product Name</label>
                        <div className="error">{errors.name?.type === 'required' && "Required"}</div>
                    </div>
                    <input id="name" className="input" type="text" {...register("name", { required: true })} onChange={(e) => nameChange(e)} />
                </div>
                <div className="field">
                    <div className="label-group">
                        <label htmlFor="slug">Link Slug</label>
                        <div className="error">{errors.slug?.type === 'required' && "Required"}</div>
                    </div>
                    <input id="slug" className="input" type="text" {...register("slug", { required: true })} />
                </div>
                <div className="field">
                    <div className="label-group">
                        <label htmlFor="price">Daily Rental Price</label>
                        <div className="error">{errors.price?.type === 'required' && "Required"}</div>
                    </div>
                    <input id="price" className="input" type="number" {...register("price", { required: true })} />
                </div>
                <div className="field">
                    <div className="label-group">
                        <label htmlFor="totalStock">Total Stock</label>
                        <div className="error">{errors.stock?.type === 'required' && "Required"}</div>
                    </div>
                    <input id="totalStock" className="input" type="number" {...register("stock", { required: true })} />

                </div>
                <div className="field">
                    <div className="label-group">
                    <label htmlFor="imageFile">Product Image (Transparent 245x245px w/ 15px safety)</label>
                    <div className="error">{errors.imageFile?.type === 'required' && "Required"}</div>
                    </div>
                    <div className="existing"><img src={getValues("thumbnailUrl")} /></div>
                    <input id="imageFile" className="input" type="file" {...register("imageFile")} />
                </div>
                <div className="field">
                    <label htmlFor="category">Category</label>
                    <select id="category" className="input" {...register("keywords", { required: true })}>
                        {
                            equipmentFilters.map(category => (
                                <option key={category.keyword} value={category.keyword}>Equipment - {category.name}</option>
                            ))
                        }
                        {
                            fullServiceFilters.map(category => (
                                <option key={category.keyword} value={category.keyword}>Full Service - {category.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="field">
                    <label>Description</label>
                    <div className="description-editor">
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <LongText onChange={field.onChange} content={field.value} />
                            )}
                        />
                        <div className="error">{errors.description?.type === 'required' && "Required"}</div>
                    </div>
                </div>
                <div>
                    <button type="submit" className="submit-button button primary">{state ? "Update" : "Add"} Product</button>
                    <button className="button" onClick={() => navigate('/admin')}>Cancel</button>
                </div>
            </form> :
            null
            }
        </div>
    )
}

export default AddEditProduct;