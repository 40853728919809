import '../styles/Cart.scss';
import CartContents from './CartContents';
import { FiShoppingCart } from 'react-icons/fi';

const Cart = ({ cartContents, setCartContents, showCart, setShowCart }) => {

    return (
        <div className="cart">

            <div className='contents'>
                <CartContents cartContents={cartContents} setCartContents={setCartContents} showCart={showCart} />
            </div>
            <div className='cart-button'>
                {
                    cartContents.length > 0 ?
                        <div className='shopping-cart' onClick={() => { setShowCart(!showCart) }}>
                            <FiShoppingCart>Cart</FiShoppingCart>
                        </div> :
                        null
                }
            </div>
        </div>
    )
}

export default Cart;