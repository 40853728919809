import moment from 'moment';

const RequestList = ({ openModal, request, getCartTotal }) => {
    return (
        <div className="request" onClick={() => openModal(request)}>
            <div className="field name">{request?.data?.contactInfo?.firstName} {request?.data?.contactInfo?.lastName}</div>
            <div className="field email">{request?.data.contactInfo?.email}</div>
            <div className="field phone">{request?.data.contactInfo?.phone}</div>
            <div className="field date-range">{moment(request?.data.eventRange?.selection?.startDate).format("M/DD/YYYY")} - {moment(request?.data?.eventRange?.selection?.endDate).format("M/DD/YYYY")}</div>
            <div className="field cart-total">${getCartTotal(request?.data?.equipment)}</div>
        </div>
    )
}

export default RequestList;