import { doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useEffect } from 'react';
import { db } from '../firebase';
import { FiX } from 'react-icons/fi';
import '../styles/EditProductServicesFilters.scss';

const EditFilters = ({ filterType, filterName }) => {
    const [filters, setFilters] = useState([]);
    const [newFilter, setNewFilter] = useState("");

    const getFilters = async () => {
        const q = query(doc(db, 'admin', filterType));
        onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.data()?.filters || [];
            const sortedData = data.sort((a, b) => {
                if(a.keyword < b.keyword) return -1;
                if(a.keyword > b.keyword) return 1;
                return 0;
            })

            setFilters(sortedData);
        })
    }

    const addNewFilter = async () => {
        const newFilterObject = {
            keyword: newFilter.replaceAll(' ', '-').toLowerCase(),
            name: newFilter
        }

        await updateDoc(doc(db, 'admin', filterType), {
            filters: [...filters, newFilterObject]
        });

        setNewFilter("");
    }

    const deleteFilter = async (filter) => {
        const filterIndex = filters.findIndex(i => i.keyword === filter.keyword);
        const tempArray = [...filters];
        tempArray.splice(filterIndex, 1);

        await updateDoc(doc(db, 'admin', filterType), {
            filters: [...tempArray]
        })
    }

    useEffect(() => {
        getFilters();
    }, [])

    return (
        <div className="edit-filters">
            <div className="header">
                <h1>{filterName}</h1>
            </div>
            <div className='add-new'>
                <input type="text" className='input' placeholder='Add New' value={newFilter} onChange={(e) => setNewFilter(e.target.value)} />
                <button className='button primary' onClick={addNewFilter}>Add New</button>
            </div>
            <div className='filters'>
                {filters?.map(filter => (
                    <div key={filter.keyword} className="filter">
                        <div className='name'>{filter.name}</div>
                        <div className='delete' onClick={() => deleteFilter(filter)}><FiX /></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EditFilters;