import { useLocalStorage } from "../../hooks/useLocalStorage";
import '../../styles/Checkout.scss';
import RentalDateNavbar from "../RentalDateNavbar";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QuantityDropdown from "../QuantityDropdown";

const Checkout = () => {
    const navigate = useNavigate();
    const [cartContents, setCartContents] = useLocalStorage("cartContents", []);
    const [dayDiff, setDayDiff] = useState(() => {
        const storedValue = JSON.parse(localStorage.getItem("eventRange"));
        if (storedValue) {
            const startDate = moment(storedValue?.selection?.startDate);
            const endDate = moment(storedValue?.selection?.endDate);

            return endDate.diff(startDate, 'days') + 1;
        }

        return -1;
    });

    const notifyDateChange = (dateRange) => {
        const startDate = moment(dateRange.startDate);
        const endDate = moment(dateRange.endDate);

        setDayDiff(endDate.diff(startDate, 'days') + 1);
    }

    const getTotalProductPrice = (productInfo) => {
        return productInfo.product.data.price * productInfo.quantity * dayDiff;
    }

    const getTotalCartPrice = () => {
        let runningTotal = 0;

        cartContents.forEach(item => {
            runningTotal += getTotalProductPrice(item);
        });

        return runningTotal;
    }

    const quantityChange = (value, itemId) => {
        const itemIndex = cartContents.findIndex((cartItem) => cartItem.product.id === itemId);
        let tempContents = [...cartContents];

        if (value === "Delete") {
            tempContents.splice(itemIndex, 1);
        }
        else {
            tempContents[itemIndex].quantity = value.toString();
        }

        setCartContents(tempContents);
    }

    const goToContactInfo = () => {
        navigate("contact-info");
    }

    useEffect(() => {
        cartContents.forEach(item => {
            item.price = getTotalProductPrice(item);
        });

        setCartContents([...cartContents])
    }, [])

    return (
        <div className="checkout-page">
            <div className="panel">
                <div className="panel-content">
                    <div><RentalDateNavbar hideSorting={true} notifyDateChange={notifyDateChange} /></div>
                    <div><h1 className="header">Checkout</h1></div>
                    <div className="cart-contents">
                        {
                            cartContents?.map(item => (
                                <div className="item" key={item.product.id}>
                                    <div className="name">
                                        {item.product.data.name}
                                    </div>
                                    <QuantityDropdown item={item} quantityChange={quantityChange} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="cart-total">
                        Grand Total: ${getTotalCartPrice()}
                    </div>
            <div className="contact-info">
                <button className="button primary" onClick={goToContactInfo}>Proceed to Contact Info</button>
            </div>
        </div>
    )
}

export default Checkout;