import { useNavigate } from 'react-router-dom';
import '../styles/CtaSection.scss';

const CtaSection = () => {
    const navigate = useNavigate();

    return (
        <div className='cta'>
            <div className='header'>
                <h1 onClick={() => navigate('/contact')}>CONTACT US AND LET'S CHANGE THE GAME TOGETHER</h1>
            </div>
        </div>
    )
}

export default CtaSection;