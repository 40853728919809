import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import parse from 'html-react-parser';
import '../../styles/Product.scss';
import RentProduct from "../RentProduct";
import RentalDateNavbar from "../RentalDateNavbar";
import { getFunctions, httpsCallable } from "firebase/functions";

const Product = ({ setCartContents, setShowCart }) => {
    const { slug } = useParams();
    const [product, setProduct] = useState();
    const [availableStock, setAvailableStock] = useState(-1);

    const getProduct = () => {
        const q = query(collection(db, 'products'), where("slug", "==", slug));
        onSnapshot(q, (querySnapshot) => {
            const snap = querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            }));

            if (snap.length === 1) {
                setProduct(snap[0]);
            }
        })
    }

    const getCurrentAvailableStock = async () => {
        const availableStockFunction = httpsCallable(getFunctions(), 'getProductAvailablityByDate');
        const eventRange = JSON.parse(localStorage.getItem("eventRange"));
        const blankDateRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            userSet: false
        };

        const stock = await availableStockFunction({
            product: product,
            eventRange: eventRange?.selection ? eventRange?.selection : blankDateRange
        });

        setAvailableStock(stock.data);
    }

    const notifyDateRange = () => {
        getCurrentAvailableStock();
    }

    useEffect(() => {
        getProduct();
    }, []);

    useEffect(() => {
        if (product) {
            getCurrentAvailableStock();
        }
    }, [product])

    return (
        <div className="product">
            <div className="content">
                <div className="images">
                    <img alt="product image" src={product?.data?.thumbnailUrl} />
                </div>
                <div className="body">
                    <h1>{product?.data?.name}</h1>
                    <div className="daily-rental-price">Daily rental from ${product?.data?.price}</div>
                    <div className="description">
                        {parse(product ? product?.data?.description : "")}
                    </div>
                </div>
            </div>
            <div className="rental-widget">
                <div className="header">
                    <h1>Check Availability</h1>
                </div>
                <div className="rental-navbar"><RentalDateNavbar notifyDateChange={notifyDateRange} hideSorting={true} /></div>
                <RentProduct setShowCart={setShowCart} setCartContents={setCartContents} productInfo={product} availableStock={availableStock} />
            </div>
        </div>
    )
}

export default Product;