import '../styles/AdminCareerSettings.scss';
import { useForm } from "react-hook-form";
import { doc, getDoc, query, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useEffect } from 'react';
import { useState } from 'react';
import { FiX } from 'react-icons/fi';

const AdminContactSettings = () => {
    const { register, handleSubmit, setValue } = useForm();
    const [recipients, setRecipients] = useState([]);

    const onSubmit = async (data) => {
        await updateDoc(doc(db, 'admin', 'contact'), {
            recipients: [...recipients, data.recipient]
        });

        getRecipients();
        setValue('recipient', "")
    }

    const getRecipients = async () => {
        const q = query(doc(db, 'admin', 'contact'));
        const docSnap = await getDoc(q);

        if(docSnap.exists() && docSnap.data().recipients) {
            setRecipients(docSnap.data().recipients);
        }
    }

    const removeRecipient = async (recipient) => {
        const recipientIndex = recipients.indexOf(recipient);
        const tempArray = [...recipients];
        tempArray.splice(recipientIndex, 1)

        await updateDoc(doc(db, 'admin', 'contact'), {
            recipients: [...tempArray]
        });

        getRecipients();
    }

    useEffect(() => {
        getRecipients();
    }, [])

    return (
        <div className="admin-contact-settings">
            <div className="email-recipients">
                <div className="add-new">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input className="input" type="text" placeholder="Add New Recipient" {...register("recipient", { required: true })} />
                        <button className="button primary">Add</button>
                    </form>
                </div>

                <div className='recipients'>
                    {
                        recipients?.map(recipient => (
                            <div key={recipient} className='recipient'>
                                <div className='email'>{recipient}</div>
                                <div className='remove' onClick={() => removeRecipient(recipient)}><FiX /></div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminContactSettings