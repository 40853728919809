import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import moment from 'moment';
import '../styles/DateRangePicker.scss';

const DateRangePicker = ({ dateSelectCallback }) => {
    const [eventDateRange, setEventDateRange] = useState(() => {
        const storedValue = JSON.parse(localStorage.getItem("eventRange"));

        if (storedValue) {
            return [{
                startDate: moment(storedValue?.selection?.startDate).toDate(),
                endDate: moment(storedValue?.selection?.endDate).toDate(),
                key: 'selection',
                userSet: true
            }]
        }
        else {
            return [{
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
                userSet: false
            }]
        }
    });

    const handleSelect = (ranges) => {
        setEventDateRange([ranges.selection]);
        localStorage.setItem("eventRange", JSON.stringify(ranges));
    }

    useEffect(() => {
        dateSelectCallback(eventDateRange[0]);
    }, [eventDateRange])

    return (
        <div className="date-range-picker">
            <DateRange ranges={eventDateRange} onChange={handleSelect} color="#af1f28" rangeColors={['#af1f28']} />
        </div>
    )
}

export default DateRangePicker;