import '../styles/EquipmentRentalTutorial.scss';
import { FiX } from 'react-icons/fi';
import { useLocalStorage } from '../hooks/useLocalStorage';

const EquipmentRentalTutorial = () => {
    const [isTutorialOpen, setIsTutorialOpen] = useLocalStorage("isEquipmentRentalTutorialOpen", true);

    return (
        <div>
            {
                isTutorialOpen ?
                    <div className="equipment-rental-tutorial">
                        <div className='close-button' onClick={() => setIsTutorialOpen(false)}>
                            <FiX />
                        </div>
                        <div className="header">
                            <h1>What am I looking at here?</h1>
                        </div>
                        <div className="content">
                            <p>Think of this as a conversation starter. Set your event date, load up your cart with any equipment and services you may need for your upcoming event, then go through the checkout process. No payment needed, just make sure your contact info is correct!</p>
                            <p>Once we receive your request, our team will reach out to you to dicuss your event, and we're off to the races! We look forward to hearing from you, and can't wait to see what you'll create next.</p>
                        </div>
                    </div> :
                    null
            }
        </div>
    )
}

export default EquipmentRentalTutorial;