import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AdminPage from "./components/pages/Admin";
import EquipmentRental from './components/pages/EquipmentRental';
import LoginPage from "./components/pages/Login";
import { auth, db } from "./firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import AddEditProduct from "./components/pages/AddEditProduct";
import Product from './components/pages/Product';
import Cart from "./components/Cart";
import { useLocalStorage } from "./hooks/useLocalStorage";
import Checkout from "./components/pages/Checkout";
import RentalContactInfo from "./components/pages/RentalContactInfo";
import Confirmation from "./components/pages/Confirmation";
import Home from "./components/pages/Home";
import Navbar from "./components/Navbar";
import About from "./components/pages/About";
import Careers from "./components/pages/Careers";
import Contact from "./components/pages/Contact";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { useState } from "react";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-S267HYL6TH";
ReactGA.initialize(TRACKING_ID);

const ProtectedRoute = ({ user, redirectPath = "login", children }) => {
  const navigate = useNavigate();

  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
  }

  if (!user) {
    navigate(redirectPath);
    return;
  }
  else {
    try {
      const q = query(collection(db, 'users'), where("uid", "==", user.uid));
      onSnapshot(q, (querySnapshot) => {
        const snap = querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        }));
        if (snap.length > 0) {
          const pendingUser = snap[0];

          if (!pendingUser.data.isAdmin) {
            navigate(redirectPath);
          }
        }
      })
    }
    catch (ex) {

    }

    localStorage.setItem('user', JSON.stringify(user));
  }

  return children ? children : <Outlet />
}

const App = () => {
  const [user] = useAuthState(auth);
  const [cartContents, setCartContents] = useLocalStorage("cartContents", []);
  const [showCart, setShowCart] = useState(false);
  const location = useLocation();

  return (
    <div id="app" className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rental" element={<EquipmentRental setCartContents={setCartContents} setShowCart={setShowCart} />} />
        <Route path="login" element={<LoginPage />} />
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="admin" element={<AdminPage />} />
        </Route>
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="admin/edit-product" element={<AddEditProduct />} />
        </Route>
        <Route path="product/:slug" element={<Product setCartContents={setCartContents} setShowCart={setShowCart} />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="checkout/contact-info" element={<RentalContactInfo />} />
        <Route path="confirmation" element={<Confirmation />} />
        <Route path="about" element={<About />} />
        <Route path="careers" element={<Careers />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
      {
        location.pathname !== '/checkout' &&
          location.pathname !== '/checkout/contact-info' &&
          location.pathname !== '/confirmation' ?
          <Cart cartContents={cartContents} setCartContents={setCartContents} showCart={showCart} setShowCart={setShowCart} /> :
          null
      }
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
