import { useEffect } from "react";
import { useRef } from "react";
import { TailSpin } from "react-loading-icons";
import '../styles/RentProduct.scss';
import { toast } from 'react-toastify';

const RentProduct = ({ productInfo, setCartContents, availableStock, setShowCart }) => {
    const quantityEl = useRef(null);

    const addToCart = () => {
        const currentValue = quantityEl.current.value
        if(parseInt(currentValue) > availableStock) {
            toast.error("Can't reserve more than amount available");
        }
        else if(currentValue === "") {
            toast.error("Please set a quantity");
        }
        else {
            setCartContents(currentCart => {
                let existingItemIndex;
                const existingItem = currentCart.filter((item, index) => {
                    existingItemIndex = index;
                    return item.product.id === productInfo.id
                });
                if (existingItem.length > 0) {
                    currentCart.splice(existingItemIndex, 1);
                    existingItem[0].quantity = parseInt(existingItem[0].quantity ? existingItem[0].quantity : 0) + parseInt(currentValue);
    
                    return [...currentCart, existingItem[0]];
                }
                else {
                    return [...currentCart, {
                        product: productInfo,
                        quantity: currentValue,
                        availableStock: availableStock
                    }]
                }
            });
            setShowCart(true);
        }
    }

    return (
        <div className="rent-product">
            <div className="available-stock">
                {
                    availableStock > 0 ? `${availableStock} available for your event!` : <div><TailSpin stroke="#121212" height='1em' /> <span>Getting availability...</span></div>
                }
            </div>
            <div className="quantity">
                <input id="quantity" className="input" type="number" ref={quantityEl} min="0" max={availableStock} placeholder="Quantity" />
            </div>
            <div className='continue'>
                <button className="button primary" disabled={quantityEl?.current?.value === "0"} onClick={addToCart}>Add to cart</button>
            </div>
        </div>
    )
}

export default RentProduct;