import { Controller, useForm } from "react-hook-form";
import ReactInputMask from 'react-input-mask';
import LongText from '../LongText';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import '../../styles/Contact.scss';

const Contact = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const fields = [
        {
            label: "Full Name",
            type: "text",
            id: "fullName",
            required: true
        },
        {
            label: "Email",
            type: "text",
            id: "email",
            required: true
        },
        {
            label: "Message",
            type: "longText",
            id: "message",
            required: true
        }
    ];
    const [showOverlay, setShowOverlay] = useState(false);

    const onSubmit = async (data) => {
        try {
            await addDoc(collection(db, "mail"), {
                to: ["gaming@ohayocon.org", "pcgaming@ohayocon.org"],
                template: {
                    name: 'contactUs',
                    data: {
                        fullName: data.fullName,
                        email: data.email,
                        message: data.message
                    }
                }
            });

            setShowOverlay(true);
        }
        catch(ex) {
            console.error(ex);
        }
    }

    const goToHome = () => {
        navigate('/');
    }

    const renderField = (formField) => {
        switch (formField.type) {
            case "text":
                return (
                    <input id={formField.id} type="text" placeholder={formField.placeholder} className='input' {...register(formField.id, { required: formField.required })} />
                )
            case "phone":
                return (
                    <Controller
                        name={formField.id}
                        control={control}
                        rule={{ required: formField.required }}
                        render={({ field }) => (
                            <ReactInputMask value={field.value ?? ""} className="input" onChange={(date) => field.onChange(date)} mask="(999) 999-9999" />
                        )} />
                )
            case "select":
                return (
                    <select id={formField.id} defaultValue="default" className="input" {...register(formField.id, { required: formField.required })}>
                        <option value="default" disabled></option>
                        {
                            formField.options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)
                        }
                    </select>
                )
            case "longText":
                return (
                    <Controller
                        name={formField.id}
                        control={control}
                        rule={{ required: formField.required }}
                        render={({ field }) => (
                            <LongText onChange={field.onChange} content={field.value} showMenu={false} />
                        )} />
                )
        }
    }

    return (
        <div className="careers-page">
            <div className='content'>
                <div className="header">
                    <h1>Talk to us!</h1>
                </div>
                <div className='tagline'>
                    <h4>Got a question, concern, or want to give us some feedback? Send us a message!</h4>
                </div>
                <div className='form-container'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='panel'>
                            {
                                fields?.map(field => (
                                    <div key={field.id} className='row'>
                                        <div className='field'>
                                            <div className='label-group'>
                                                <label htmlFor={field.id}>{field.label}</label>
                                                {
                                                    field.required ? <div className="error">{errors[field.id]?.type === 'required' && "(required)"}</div> : null
                                                }
                                            </div>
                                            <div className='control'>
                                                {renderField(field)}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            <div className='row'>
                                <div className='submit-button'>
                                    <button type="submit" className='button primary'>Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className={`overlay ${showOverlay ? 'visible' : ''}`}>
                <div className='panel'>
                    <div className='header'>Message Sent</div>
                    <div className='body'>
                        <p>Thanks for reaching out! We'll make sure we address your message ASAP!</p>
                        <button className='primary button' onClick={goToHome}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;