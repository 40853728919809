import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";

const AdminProductList = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState();
    const [searchedProducts, setSearchedProducts] = useState();
    const [ searchTerm ] = useState();

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        const q = query(collection(db, 'products'), orderBy("name"));
        onSnapshot(q, (querySnapshot) => {
            let snap = querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            }));

            setProducts(snap);
            setSearchedProducts(snap);
        });
    }

    const editProduct = (product) => {
        navigate('edit-product', { state: product });
    }

    const deleteProduct = async (product) => {
        if (window.confirm("Are you sure you want to delete this product?")) {
            const productDocRef = doc(db, 'products', product.id);
            await deleteDoc(productDocRef);
        }
    }

    const searchTermChange = (term) => {
        if(term === "") {
            setSearchedProducts(products);
        }
        else {
            const tempSearchedProducts = products.filter(product => product.data.name.toLowerCase().includes(term));
            setSearchedProducts(tempSearchedProducts);
        }
    }

    return (
        <div className="admin-product-list">
            <div className="controls">
                <button className="button" onClick={() => navigate('edit-product')}>Add New</button>
                <input type="text" className="input" placeholder="Search" value={searchTerm} onChange={(e) => { searchTermChange(e.target.value) }} />
            </div>
            <div className="products-list">
                {
                    searchedProducts?.map(product => (
                        <div className="product-row" key={product.id}>
                            <div className="item name">{product.data.name}</div>
                            <div className="item price">${product.data.price}/day</div>
                            <div className="item stock">{product.data.stock} available</div>
                            <div className="item edit-delete">
                                <button className="edit-button button primary" onClick={() => editProduct(product)}>Edit</button>
                                <button className="delete-button button" onClick={() => deleteProduct(product)}>Delete</button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default AdminProductList;