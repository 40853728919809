import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/CartContents.scss';
import QuantityDropdown from "./QuantityDropdown";

const CartContents = ({ cartContents, setCartContents, showCart }) => {
    const navigate = useNavigate();
    const [subtotal, setSubtotal] = useState();

    const calculateSubtotal = () => {
        let runningTotal = 0;

        cartContents.forEach(item => {
            runningTotal += parseFloat(item.product.data.price) * parseInt(item.quantity);
        })

        setSubtotal(runningTotal);
    }

    const quantityChange = (value, itemId) => {
        if(value === "") {
            value = "0";
        }
        const itemIndex = cartContents.findIndex((cartItem) => cartItem.product.id === itemId);
        let tempContents = [...cartContents];

        if (value === "Delete") {
            tempContents.splice(itemIndex, 1);
        }
        else {
            tempContents[itemIndex].quantity = value.toString();
        }

        setCartContents(tempContents);
    }

    useEffect(() => {
        calculateSubtotal();
    }, [cartContents])

    const goToCheckout = () => {
        navigate("/checkout");
    }

    return (
        <div className={`container ${showCart && cartContents.length > 0 ? 'expanded' : ''}`}>
            <div className="cart-contents">
                <div>
                    <div className='subtotal'>Subtotal Estimate: <strong className="price">${subtotal}</strong> <span className="label">(per day)</span></div>
                    <div className="items">
                        {
                            cartContents?.map(item => (
                                <div key={item.product.id} className="item">
                                    <div className="quantity">
                                        <QuantityDropdown item={item} quantityChange={quantityChange} />
                                    </div>
                                    <div className="name">
                                        {item.product.data.name}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div>
                    {
                        cartContents?.length > 0 ? <button className="checkout primary button" onClick={goToCheckout}>Checkout</button> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default CartContents;