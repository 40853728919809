import { useEffect, useState } from "react";
import '../styles/QuickAddQuantity.scss';

const QuickAddQuantity = ({ quantity, quantityChange, availableStock }) => {
    const options = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"];
    const [isTenOrMore, setIsTenOrMore] = useState(false);

    useEffect(() => {
        const quantityInt = parseInt(quantity);

        if (quantityInt >= 10 || isTenOrMore) {
            setIsTenOrMore(true)
        }
        else {
            setIsTenOrMore(false);
        }
    }, [quantity]);

    return (
        <div className="quick-add-quantity">
            {
                isTenOrMore ?
                    <input type="number" className="input" min="0" value={quantity === "10+" ? "10" : quantity} onChange={(e) => quantityChange(e.target.value)} /> :
                    <select className="input" value={quantity || "0"} onChange={(e) => quantityChange(e.target.value)}>
                        <option value="0"></option>
                        {
                            options.map(option => <option key={option} value={option}>{option}</option>)
                        }
                    </select>
            }
        </div>
    )
}

export default QuickAddQuantity;