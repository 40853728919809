import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import UserRow from "./UserRow";
import '../styles/UserManagement.scss';

const UserManagement = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        const q = query(collection(db, 'users'))
        await onSnapshot(q, (querySnapshot) => {
            const snap = querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            }));
            setUsers(snap);
        })
    }

    return (
        <div className="user-management">
            <table className="user-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Is Admin</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map(user => (
                            <UserRow key={user.id} user={user} />
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default UserManagement;