import { collection, deleteDoc, doc, getDoc, onSnapshot, query, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import ReactModal from 'react-modal';
import moment from 'moment';
import '../styles/EquipmentRequests.scss';
import { useForm } from "react-hook-form";
import RequestList from "./RequestList";
import { FiX } from 'react-icons/fi';

const EquipmentRequests = () => {
    const [requests, setRequests] = useState();
    const [recipients, setRecipients] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { register, handleSubmit, setValue } = useForm();
    const [modalContents, setModalContents] = useState({
        contactInfo: {},
        equipment: [],
        dateRange: {}
    });
    const getEquipmentRequests = () => {
        const q = query(collection(db, "equipmentRequests"));
        onSnapshot(q, (querySnapshot) => {
            const snap = querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            }));

            setRequests(snap);
        })
    }

    const onSubmit = async (data) => {
        await updateDoc(doc(db, 'admin', 'equipmentRequests'), {
            recipients: [...recipients, data.recipient]
        });

        getRecipients();
        setValue('recipient', "")
    }

    const removeRecipient = async (recipient) => {
        const recipientIndex = recipients.indexOf(recipient);
        const tempArray = [...recipients];
        tempArray.splice(recipientIndex, 1)

        await updateDoc(doc(db, 'admin', 'equipmentRequests'), {
            recipients: [...tempArray]
        });

        getRecipients();
    }

    const getCartTotal = (cart) => {
        let runningTotal = 0;
        cart?.map(item => runningTotal += item.price);

        return runningTotal;
    }

    const openModal = (contents) => {
        setModalContents(contents);
        setIsModalOpen(true);
    }

    const filterRequests = (requests, expectedValue) => {
        return requests?.filter(request => request.data.approved === expectedValue);
    }

    const approveDenyRequest = async (content, isApproved) => {
        const userDocRef = doc(db, 'equipmentRequests', content.id);
        await updateDoc(userDocRef, { approved: isApproved });
        setIsModalOpen(false);
    }

    const deleteRequest = async (content) => {
        if(window.confirm("Are you sure you want to delete this request?")) {
            const docRef = doc(db, 'equipmentRequests', content.id);
            await deleteDoc(docRef);
            setIsModalOpen(false);
        }
    }

    const getRecipients = async () => {
        const q = query(doc(db, 'admin', 'equipmentRequests'));
        const docSnap = await getDoc(q);

        if (docSnap.exists() && docSnap.data().recipients) {
            setRecipients(docSnap.data().recipients);
        }
    }

    useEffect(() => {
        getEquipmentRequests();
        getRecipients();
    }, [])

    return (
        <div className="equipment-requests">
            <div className="email-recipients section">
                <div className="header">
                    <h4>Email Recipients</h4>
                </div>
                <div className="add-new">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input className="input" type="text" placeholder="Add New Recipient" {...register("recipient", { required: true })} />
                        <button className="button primary">Add</button>
                    </form>
                </div>

                <div className='recipients'>
                    {
                        recipients?.map(recipient => (
                            <div key={recipient} className='recipient'>
                                <div className='email'>{recipient}</div>
                                <div className='remove' onClick={() => removeRecipient(recipient)}><FiX /></div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="pending-requests section">
                {filterRequests(requests, undefined)?.length > 0 ? <h4>Pending</h4> : null}
                {
                    filterRequests(requests, undefined)?.map(request => (
                        <RequestList key={request.id} openModal={openModal} request={request} getCartTotal={getCartTotal} />
                    ))
                }
            </div>
            <div className="approved-requests section">
                {filterRequests(requests, true)?.length > 0 ? <h4>Approved</h4> : null}
                {
                    filterRequests(requests, true)?.map(request => (
                        <RequestList key={request.id} openModal={openModal} request={request} getCartTotal={getCartTotal} />
                    ))
                }
            </div>
            <div className="denied-requests section">
                {filterRequests(requests, false)?.length > 0 ? <h4>Denied</h4> : null}
                {
                    filterRequests(requests, false)?.map(request => (
                        <RequestList key={request.id} openModal={openModal} request={request} getCartTotal={getCartTotal} />
                    ))
                }
            </div>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                appElement={document.getElementById('app') || undefined}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                style={{
                    content: { width: '25vw', left: '37vw', height: '60vh', top: '20vh' },
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
                }}
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div className="modal-contents">
                    <div className="name-date">
                        <div>
                            <h1>{modalContents?.data?.contactInfo?.firstName} {modalContents?.data?.contactInfo?.lastName}</h1>
                        </div>
                        <div>
                            <div className="start-date">
                                <h4>{moment(modalContents?.data?.eventRange?.selection?.startDate).format("MMMM do, YYYY")}</h4>
                            </div>
                            <div className="end-date">
                                to {moment(modalContents?.data?.eventRange?.selection?.endDate).format("MMMM do, YYYY")}
                            </div>
                        </div>
                    </div>
                    <div className="address">
                        <div className="address1">{modalContents?.data?.contactInfo?.address1}</div>
                        <div className="address2">{modalContents?.data?.contactInfo?.address2}</div>
                        <div className="city-state-zip">{modalContents?.data?.contactInfo?.city}, {modalContents?.data?.contactInfo?.state} {modalContents?.data?.contactInfo?.zip}</div>
                    </div>

                    <div className="cart-contents">
                        {
                            modalContents?.data?.equipment?.map(item => (
                                <div key={item.product.id} className="item">
                                    <div className="thumbnail">
                                        <img src={item.product.data.thumbnailUrl} />
                                    </div>
                                    <div className="content">
                                        <div className="name">
                                            {item.product.data.name}
                                        </div>
                                        <div className="quantity">
                                            Qty: {item.quantity}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="footer">
                        <div className="total">
                            <h3>Request Total: ${getCartTotal(modalContents?.data?.equipment)}</h3>
                        </div>

                        <div className="buttons">
                            <div className="delete">
                                <div className="delete-button">
                                    <button className="button" onClick={() => deleteRequest(modalContents)}>Delete</button>
                                </div>
                            </div>
                            <div className="approve-deny">
                                <button className="approve button primary" onClick={() => approveDenyRequest(modalContents, true)}>Approve</button>
                                <button className="button" onClick={() => approveDenyRequest(modalContents, false)}>Deny</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}

export default EquipmentRequests;