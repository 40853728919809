import '../../styles/About.scss';
import Typewriter from 'typewriter-effect';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const About = () => {
    const byTheNumbers = [
        {
            name: 'brands',
            number: 20,
            description: "Brands we've worked with to date",
            plus: true
        },
        {
            name: 'participants',
            number: 45000,
            description: "Participants at our events--and counting!",
            plus: true
        },
        {
            name: 'views',
            number: 1200000,
            description: "Views via online streams",
            plus: true
        },
        {
            name: 'digital',
            number: 15000000,
            description: "Reached via our digital work",
            plus: true
        },
        {
            name: 'nominations',
            number: 2,
            description: "Award Nominated Productions",
            plus: false
        },
        {
            name: 'locations',
            number: 2,
            description: "Locations and Studios",
            plus: false
        },
        {
            name: 'states',
            number: 14,
            description: "States we've produced events in",
            plus: false
        },
        {
            name: 'team',
            number: 15,
            description: "Professionals waiting and ready to work with you",
            plus: true
        },
    ]

    return (
        <div className="about-page">
            <div className="hero">
                <div className="shade">
                    <div className='content'>
                        <h1>We believe in unforgettable</h1>
                        <Typewriter onInit={(typewriter) => {
                            typewriter
                                .typeString('EXPERIENCES')
                                .pauseFor(3000)
                                .deleteAll()
                                .typeString('EVENTS')
                                .pauseFor(3000)
                                .deleteAll()
                                .typeString('SHOWS')
                                .pauseFor(3000)
                                .deleteAll()
                                .typeString('CELEBRATIONS')
                                .pauseFor(3000)
                                .deleteAll()
                                .typeString('MOMENTS')
                                .pauseFor(3000)
                                .deleteAll()
                                .typeString('STORIES')
                                .pauseFor(3000)
                                .deleteAll()
                                .start()
                        }} options={{ deleteSpeed: 40, loop: true }} />
                    </div>
                </div>
            </div>
            <div className='mission-statement'>
                <div className='section'>
                    <div className='side-image'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/about-page%2F2be638c56d65c660bc6bcfbdc2b4cd34.jpg?alt=media&token=e80bb29c-45fd-4764-bb3a-89d6079221e7" />
                    </div>
                    <div className='content definition'>
                        <p><strong>Van-guard (n)</strong></p>
                        <p>A group of people leading the way in new developments or ideas</p>
                    </div>
                </div>
                <div className='section even'>
                    <div className='content vision'>
                        <p>At <strong>Vanguard</strong>, we are dedicated to empowering visionaries by equipping them with the tools, expertise, staff, and creative solutions necessary to champion communities, competitions, and platforms.</p>
                    </div>
                    <div className='side-image'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/about-page%2F9be9c63dd921e7fa76e3d478217a196e.jpg?alt=media&token=4b5fd670-cfb6-425d-bb79-2467d29cab31" />
                    </div>
                </div>
                <div className='section'>
                    <div className='side-image'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/about-page%2Fe3dc1f540bf29d930b825b2f99f96d83.jpg?alt=media&token=19b33cbe-4f62-421b-b6d8-c406e94938dd" />
                    </div>
                    <div className='content history'>
                        <p>As a trailblazer in the Esports industry, we offer a comprehensive inventory. Our commitment to transparency sets us apart – we proudly display our rental costs, allowing you to tailor your event to any specific need.
With a combined experience of over 50 years, Vanguard constantly strives to be at the forefront of what is possible.</p>
                    </div>
                </div>
                <div className='section even'>
                    <div className='content autobiography'>
                        <p>Our success lies in our people. As a proud member of the Esports Foundry family, diversity is at the core of our business. We bring together a team with diverse skills and mindsets, enabling Vanguard to be your ultimate partner and problem solver.
                        </p>
                    </div>
                    <div className='side-image'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/vanguard-gg.appspot.com/o/about-page%2F71b9573df1e874a0af238e233cd656af.jpg?alt=media&token=4ccee95c-841d-45fe-8eb9-9a64ee4e87b2" />
                    </div>
                </div>
            </div>
            <div className='by-the-numbers'>
                <div className='shade'>
                    <div className='header'>
                        <h1>Vanguard</h1>
                        <h4>By the numbers</h4>
                    </div>
                    <div className='content'>
                        {
                            byTheNumbers?.map((item) => (
                                <div key={item.name} className='item-container'>
                                    <div className='item'>
                                        <div className='number'>
                                            <CountUp delay={Math.random()/2} end={item.number} duration={1+Math.random()} separator=",">
                                                {({countUpRef, start}) => (
                                                    <VisibilitySensor onChange={start} delayedCall>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                            {item.plus ? <span className='plus'>+</span> : null}
                                        </div>
                                        <div className='description'>
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;